/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
*::before,
*::after {
    -webkit-animation-duration: 0.01ms !important;
            animation-duration: 0.01ms !important;
    -webkit-animation-iteration-count: 1 !important;
            animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-size: 1.6rem;
  font-family: "Open Sans", sans-serif;
}

button {
  font-family: "Open Sans", sans-serif;
}

.wrapper {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
}

.fixed-body {
  overflow: hidden;
}

.line-break {
  display: block;
}

.text-hidden {
  display: none;
  color: #fff;
}

.skip-link {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  margin: 0;
  padding: 0.5rem;
  background-color: yellow;
  color: #000;
  text-decoration: none;
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  z-index: 99;
}
.skip-link:focus {
  width: auto;
  height: auto;
  clip: auto;
}

.gallery-box {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
}
.gallery-box::before {
  content: url("../../../dist/assets/icons/fullscreen-icon.svg");
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  fill: #fff;
  opacity: 0;
  transform: scale(2.5);
  transition: opacity 0.3s;
  transform-origin: center;
  z-index: 2;
}
.gallery-box img {
  transition: transform 0.3s;
}
.gallery-box:hover::before {
  opacity: 1;
}
.gallery-box:hover img {
  transform: scale(1.4);
}

.main {
  position: relative;
}

@media screen and (min-width: 768px) {
  .text-hidden {
    display: inline;
  }
}
:root {
  --header-bgimg-mobile: url("../../../dist/assets/images/hero/hero-mobile.webp");
  --header-bgimg-desktop: url("../../../dist/assets/images/hero/hero-desktop.webp");
}

.navbar {
  display: grid;
  grid-template-columns: 100px 1fr 100px;
  align-items: center;
  position: absolute;
  width: 100%;
  padding: 1em;
  z-index: 1;
}
.navbar__burger-button {
  padding: 0.5em 0;
  background: none;
  border: 0;
  z-index: 10;
  cursor: pointer;
}
.navbar__burger-button--active .navbar__burger-bar::before {
  transform-origin: left;
  -webkit-animation: 500ms ease 0ms 1 normal hamburgerTopBar forwards;
          animation: 500ms ease 0ms 1 normal hamburgerTopBar forwards;
}
.navbar__burger-button--active .navbar__burger-bar::after {
  transform-origin: right;
  -webkit-animation: hamburgerBottomBar 0.5s forwards;
          animation: hamburgerBottomBar 0.5s forwards;
}
.navbar__burger-box {
  display: flex;
  align-items: center;
  width: 25px;
  height: 25px;
}
.navbar__burger-bar {
  position: relative;
  display: block;
  width: 100%;
  height: 2px;
  background-color: transparent;
}
.navbar__burger-bar::before, .navbar__burger-bar::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
}
.navbar__burger-bar::before {
  top: -5px;
  transform-origin: right;
}
.navbar__burger-bar::after {
  top: 5px;
  transform-origin: left;
}
.navbar__socialmedia-box {
  justify-self: end;
  display: flex;
  align-items: center;
  gap: 0.5em;
  z-index: 1;
}
.navbar__socialmedia-link {
  transition: opacity 0.3s;
  padding: 0.5em;
}
.navbar__socialmedia-link:hover {
  opacity: 0.7;
}
.navbar__list {
  visibility: hidden;
  display: grid;
  align-content: center;
  gap: 1em;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-left: 1em;
  background-color: #000;
  list-style-type: none;
  transform: translateX(-100%);
  transition: transform 0.4s;
}
.navbar__list--active {
  visibility: visible;
  transform: translateX(0);
}
.navbar__list-item:not(.navbar__list-item-image, .navbar__list-item--highlight) {
  position: relative;
}
.navbar__list-item:not(.navbar__list-item-image, .navbar__list-item--highlight)::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 2.5px;
  background-color: #fff;
  transform: scaleX(0);
  transition: transform 0.3s;
}
.navbar__list-item:not(.navbar__list-item-image, .navbar__list-item--highlight):hover::before {
  transform: scaleX(1);
}
.navbar__list-item--highlight {
  cursor: pointer;
}
.navbar__list-item--highlight a {
  display: inline-block;
  padding: 10px 30px;
  background-color: #33627c;
  font-weight: bold;
  border-radius: 10px;
  width: 110px;
  text-align: center;
}
.navbar__list-item--highlight a:hover {
  background-color: rgb(41, 184, 187);
}
.navbar__list-item a {
  padding: 0.5em 0;
  color: #fff;
  font-size: 1.8rem;
  text-decoration: none;
}
.navbar__list-item-image {
  transition: opacity 0.3s;
  display: none;
  width: 100px;
}
.navbar__list-item-image:hover {
  opacity: 0.7;
}
.navbar__list-item--hidden {
  display: none;
}
.navbar__language-box {
  visibility: hidden;
  display: flex;
  gap: 0.5em;
  position: absolute;
  left: 50%;
  bottom: -70vh;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 0.4s 0.5s;
  z-index: 1;
}
.navbar__language-box--active {
  visibility: visible;
  opacity: 1;
}
.navbar__language-button {
  transition: opacity 0.3s;
  padding: 0.5em;
  border: 0;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}
.navbar__language-button:hover {
  opacity: 0.7;
}
.navbar__language-button--active {
  font-weight: 900;
}
.navbar__language-wall {
  display: block;
  width: 2px;
  height: 40px;
  background-color: #fff;
  transform-origin: center;
  transform: scale(0.4);
}
.navbar__logo {
  z-index: 1;
  justify-self: center;
}
.navbar__logo img {
  width: 100px;
}

@-webkit-keyframes hamburgerTopBar {
  0% {
    transform: rotate(0) scaleX(1);
    transform-origin: left center;
  }
  30% {
    transform: rotate(0) scaleX(0);
  }
  31% {
    transform: translate(3px, -5px) rotate(45deg) scaleX(0);
  }
  80%, 100% {
    transform: translate(3px, -5px) rotate(45deg) scaleX(1);
  }
}

@keyframes hamburgerTopBar {
  0% {
    transform: rotate(0) scaleX(1);
    transform-origin: left center;
  }
  30% {
    transform: rotate(0) scaleX(0);
  }
  31% {
    transform: translate(3px, -5px) rotate(45deg) scaleX(0);
  }
  80%, 100% {
    transform: translate(3px, -5px) rotate(45deg) scaleX(1);
  }
}
@-webkit-keyframes hamburgerBottomBar {
  0% {
    transform: rotate(0) scaleX(1);
    transform-origin: right center;
  }
  50% {
    transform: rotate(0) scaleX(0);
  }
  51%, 55% {
    transform: translate(-4px, -15.5px) rotate(-45deg) scaleX(0);
  }
  100% {
    transform: translate(-4px, -15.5px) rotate(-45deg) scaleX(1);
  }
}
@keyframes hamburgerBottomBar {
  0% {
    transform: rotate(0) scaleX(1);
    transform-origin: right center;
  }
  50% {
    transform: rotate(0) scaleX(0);
  }
  51%, 55% {
    transform: translate(-4px, -15.5px) rotate(-45deg) scaleX(0);
  }
  100% {
    transform: translate(-4px, -15.5px) rotate(-45deg) scaleX(1);
  }
}
.header {
  display: flex;
  align-items: center;
  position: relative;
  padding: 2em 1em;
  min-height: 95vh;
  background-image: var(--header-bgimg-mobile);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
}
.header::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background: linear-gradient(10deg, hsla(0deg, 0%, 100%, 0), rgb(37, 36, 42));
  z-index: -1;
}
.header__container {
  display: grid;
  gap: 1.5em;
  margin-top: 5em;
}
.header__heading {
  font-size: 3rem;
  line-height: 1.6em;
}
.header__button {
  position: relative;
  overflow: hidden;
  transition: color 0.3s;
  z-index: 0;
  justify-self: start;
  padding: 1em 1.5em;
  text-decoration: none;
  text-transform: uppercase;
  border: 2px solid #fff;
  border-radius: 35px;
  letter-spacing: 0.1em;
}
.header__button::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #fff;
  transform: scaleY(0);
  transition: transform 0.3s;
  transform-origin: bottom;
  z-index: -1;
}
.header__button:hover {
  color: #000;
}
.header__button:hover::before {
  transform: scaleY(1);
}
.header__text {
  font-weight: 300;
}
.header__heading, .header__text, .header__button {
  color: #fff;
}
.header__heading, .header__text {
  font-family: "Gabriola", "Open Sans", sans-serif;
}
.header__heading {
  font-size: 4.4rem;
  line-height: 1.2em;
}
.header__text {
  font-size: 2.2rem;
}

.about {
  position: relative;
  display: grid;
  gap: 1em;
  padding: 4em 1em 2em 1em;
}
.about__heading {
  color: #4f778a;
  font-weight: 400;
  font-size: 3.6rem;
  text-align: center;
}
@media (min-width: 768px) {
  .about__heading {
    font-size: 4rem;
  }
}
@media (min-width: 1200px) {
  .about__heading {
    font-size: 5rem;
  }
}
.about__text {
  font-weight: 300;
}
.about__icon {
  justify-self: center;
}
@media screen and (min-width: 768px) {
  .about {
    grid-template-columns: repeat(2, 1fr);
    justify-items: end;
    -moz-column-gap: 3em;
         column-gap: 3em;
    padding: 6em 1em 2em 1em;
  }
  .about__heading {
    grid-row: 1/2;
    line-height: 4rem;
    justify-self: center;
  }
  .about__image {
    position: absolute;
    grid-column: 2/3;
    grid-row: 1/3;
    width: 100%;
    height: 600px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .about__text {
    grid-column: 1/2;
    text-align: right;
  }
  .about__icon {
    grid-column: 1/2;
    margin-top: 1em;
    width: 50px;
  }
}
@media screen and (min-width: 1200px) {
  .about__heading {
    margin-bottom: 0.4em;
  }
  .about__icon {
    width: 70px;
    justify-self: center;
    align-self: end;
    width: 100px;
    margin-top: 2.5em;
  }
  .about__text {
    font-size: 1.7rem;
  }
}

.products {
  position: relative;
  display: grid;
  gap: 1em;
  padding: 4em 1em 2em 1em;
}
.products__heading {
  color: #4f778a;
  font-weight: 400;
  font-size: 3.6rem;
  text-align: center;
}
@media (min-width: 768px) {
  .products__heading {
    font-size: 4rem;
  }
}
@media (min-width: 1200px) {
  .products__heading {
    font-size: 5rem;
  }
}
.products__text {
  font-weight: 300;
}
.products__icon {
  justify-self: center;
}
@media screen and (min-width: 768px) {
  .products {
    grid-template-columns: repeat(2, 1fr);
    justify-items: end;
    -moz-column-gap: 3em;
         column-gap: 3em;
    padding: 6em 1em 2em 1em;
    justify-items: start;
  }
  .products__heading {
    grid-row: 1/2;
    line-height: 4rem;
    justify-self: center;
  }
  .products__image {
    position: absolute;
    grid-column: 2/3;
    grid-row: 1/3;
    width: 100%;
    height: 600px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .products__text {
    grid-column: 1/2;
    text-align: right;
  }
  .products__icon {
    grid-column: 1/2;
    margin-top: 1em;
    width: 50px;
  }
  .products__image {
    grid-column: 1/2;
  }
  .products__heading, .products__text, .products__icon {
    grid-column: 2/3;
  }
  .products__text {
    text-align: left;
  }
}
@media screen and (min-width: 1200px) {
  .products__heading {
    margin-bottom: 0.4em;
  }
  .products__icon {
    width: 70px;
    margin-top: 2.5em;
  }
  .products__text {
    font-size: 1.7rem;
  }
}

.offer {
  position: relative;
  padding: 4em 1em;
  background-color: #2f2d32;
  z-index: 0;
}
.offer__heading, .offer__text {
  color: #fff;
}
.offer__heading {
  font-size: 3.6rem;
  font-weight: 400;
}
.offer__background-decoration {
  display: block;
  position: absolute;
  top: 35%;
  opacity: 0.2;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.offer__background-image {
  transform: scale(1.2);
}
.offer__container {
  position: relative;
  display: grid;
  gap: 1.5em;
  z-index: 0;
}
.offer__decoration {
  justify-self: center;
}
.offer__carousel {
  margin-top: 1em;
}
.offer__carousel-item {
  margin-right: 1em;
  height: 300px;
  -o-object-fit: cover;
     object-fit: cover;
}
.offer__carousel-box {
  display: grid;
  place-items: center;
  width: 100%;
}
.offer__carousel-box img {
  margin-right: 0;
  -o-object-fit: cover;
     object-fit: cover;
}
.offer__carousel-title {
  margin: 1em 0;
  color: #fff;
  font-size: 1.8rem;
}
.offer__button {
  justify-self: start;
  padding: 0.5em 3em;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.2em;
  border-radius: 25px;
  background-color: #4f778a;
  color: #fff;
  position: relative;
  overflow: hidden;
  transition: color 0.3s;
  z-index: 0;
}
.offer__button::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgb(41, 184, 187);
  transform: scaleY(0);
  transition: transform 0.3s;
  transform-origin: bottom;
  z-index: -1;
}
.offer__button:hover::before {
  transform: scaleY(1);
}
.offer__subheading {
  position: relative;
  margin-top: 2em;
  color: #fff;
  font-size: 2.8rem;
  font-weight: 400;
}
.offer__subheading::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100px;
  height: 5px;
  background-color: #4f778a;
  border-radius: 10px;
}
.offer__text--bold {
  font-weight: 600;
  font-size: 1.8rem;
}
.offer__text--highlighted {
  position: relative;
  padding: 0.4em;
  font-size: 1.9rem;
  font-weight: 600;
  color: #091613;
  background-color: #c7c9bb;
  border-radius: 5px;
  z-index: 0;
}
.offer__text--highlighted strong {
  font-weight: 900;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  gap: 1em;
  padding: 4em 1em;
}
.gallery__fullview {
  position: relative;
  visibility: hidden;
  display: grid;
  place-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 0.5em;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
}
.gallery__fullview--active {
  visibility: visible;
}
.gallery__button-close {
  position: absolute;
  top: 50px;
  right: 50px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
.gallery__button {
  display: none;
  position: absolute;
  bottom: 20px;
  padding: 2em;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  z-index: 12;
}
.gallery__button-icon {
  transform: scale(2.5);
  pointer-events: none;
}
.gallery__button--left {
  margin-right: 5em;
}
.gallery__button--right {
  margin-left: 5em;
}
.gallery__button--active {
  display: block;
}
.gallery__heading {
  color: #4f778a;
  font-weight: 400;
  font-size: 3.6rem;
  text-align: center;
  grid-row: 1/2;
  grid-column: 1/3;
  margin-bottom: 1.5em;
}
@media (min-width: 768px) {
  .gallery__heading {
    font-size: 4rem;
  }
}
@media (min-width: 1200px) {
  .gallery__heading {
    font-size: 5rem;
  }
}
.gallery__decoration {
  grid-column: 1/3;
  justify-self: center;
  margin-top: 3em;
}
.gallery__item {
  width: 100%;
  height: 150px;
}
.gallery__image {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.whyus {
  padding: 2em 1em 4em;
}
.whyus__heading {
  color: #4f778a;
  font-weight: 400;
  font-size: 3.6rem;
  text-align: center;
  text-align: center;
}
@media (min-width: 768px) {
  .whyus__heading {
    font-size: 4rem;
  }
}
@media (min-width: 1200px) {
  .whyus__heading {
    font-size: 5rem;
  }
}
.whyus__text {
  margin: 2em auto 0;
  max-width: 700px;
  text-align: center;
}
.whyus__container {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.whyus__item {
  margin-top: 3em;
}
.whyus__item-image {
  margin: 0 auto;
  width: 70px;
}
.whyus__item-title {
  margin-top: 1.5em;
  color: #706e74;
  font-size: 1.9rem;
  font-weight: 600;
  text-transform: uppercase;
}
.whyus__item-text {
  margin: 0.5em auto 0;
  max-width: 300px;
}
.whyus__bottomtext {
  margin: 2em auto 0;
  max-width: 800px;
  text-align: center;
}
.whyus__brand {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2em;
  margin-top: 3em;
}
.whyus__brand-item {
  max-width: 130px;
}
.whyus__brand-item--bigger {
  max-width: 180px;
}
.whyus__brand-item--bigger:nth-child(3) {
  max-width: 160px;
}
.whyus__decoration {
  margin: 0 auto;
  margin-top: 3em;
}

.contact {
  margin: 4em 1em;
  padding: 2em 1em;
  background-color: #2f2d32;
}
.contact__heading {
  color: #878686;
  font-weight: 400;
}
.contact__heading--highlighted {
  color: #fff;
}
.contact__form {
  margin-top: 2em;
  display: grid;
  gap: 1.5em;
}
.contact__form-input {
  padding: 1em 0.5em;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #878686;
  color: #fff;
}
.contact__form-input::-moz-placeholder {
  color: #878686;
}
.contact__form-input::placeholder {
  color: #878686;
}
.contact__form-textarea {
  resize: vertical;
}
.contact__form-button {
  padding: 0.5em 3em;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.2em;
  border-radius: 25px;
  background-color: #4f778a;
  color: #fff;
  transition: opacity 0.3s;
  margin-top: 2em;
  border: 0;
  cursor: pointer;
}
.contact__form-button:hover {
  opacity: 0.7;
}
.contact__modal {
  display: none;
  flex-direction: column;
  align-items: center;
  gap: 1.5em;
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 1em;
  width: 95%;
  color: #fff;
  background-color: #000;
  text-align: center;
  border-radius: 10px;
  z-index: 10;
  transform: translate(-50%, -50%);
}
.contact__modal-heading {
  font-size: 2.6rem;
  font-weight: 600;
}
.contact__modal-button {
  position: relative;
  overflow: hidden;
  transition: color 0.3s;
  z-index: 0;
  padding: 0.5em 3em;
  color: #fff;
  background-color: #33627c;
  border: 0;
  border-radius: 20px;
  cursor: pointer;
}
.contact__modal-button::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgb(41, 184, 187);
  transform: scaleY(0);
  transition: transform 0.3s;
  transform-origin: bottom;
  z-index: -1;
}
.contact__modal-button:hover::before {
  transform: scaleY(1);
}
.contact__modal--active {
  display: flex;
}

.footer {
  padding: 3em 1em;
  background-color: #2f2d32;
}
.footer__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
}
.footer__heading {
  grid-row: 1/2;
  grid-column: 1/3;
  color: #fff;
}
.footer__logo {
  grid-column: 1/3;
  justify-self: center;
}
.footer__logo-image {
  width: 150px;
}
.footer__socialmedia, .footer__list {
  list-style-type: none;
}
.footer__contact {
  grid-column: 1/2;
}
.footer__contact-text {
  margin-top: 0.5em;
  color: #fff;
}
.footer__contact-link {
  color: #fff;
  transition: opacity 0.3s;
}
.footer__contact-link:hover {
  opacity: 0.7;
}
.footer__list {
  grid-column: 2/3;
  justify-self: end;
}
.footer__list-item {
  margin-top: 0.5em;
  text-align: right;
}
.footer__list-item a {
  transition: opacity 0.3s;
  text-decoration: none;
  color: #fff;
}
.footer__list-item a:hover {
  opacity: 0.7;
}
.footer__socialmedia {
  display: flex;
  gap: 3em;
  grid-column: 1/3;
  justify-self: center;
  margin-top: 2em;
}
.footer__socialmedia-item {
  transition: opacity 0.3s;
}
.footer__socialmedia-item:hover {
  opacity: 0.7;
}
.footer__author {
  grid-column: 1/3;
  margin-top: 3em;
  padding-top: 1em;
  color: #fff;
  border-top: 1px solid #878686;
  text-align: center;
}
.footer__author a {
  color: rgb(46, 212, 181);
}

@media (min-width: 500px) {
  .gallery {
    grid-template-columns: repeat(2, 200px);
    justify-content: center;
  }
  .gallery__fullview-image {
    width: 500px;
  }
  .gallery__item {
    height: 200px;
  }
  .contact__modal {
    width: 450px;
  }
}
@media screen and (min-width: 768px) {
  .navbar__socialmedia-box {
    gap: 1em;
  }
  .header__text {
    max-width: 400px;
  }
  .header__heading {
    font-size: 3.6rem;
  }
  .header__container {
    grid-template-columns: repeat(2, 1fr);
  }
  .header__heading, .header__text, .header__button {
    grid-column: 2/3;
  }
  .offer__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    -moz-column-gap: 3em;
         column-gap: 3em;
  }
  .offer__image {
    grid-column: 1/2;
    grid-row: 1/4;
    position: absolute;
  }
  .offer__heading, .offer__text, .offer__button, .offer__decoration {
    grid-column: 2/3;
  }
  .offer__carousel {
    grid-column: 1/3;
    margin-top: 2em;
  }
  .offer__carousel-box {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
  .offer__carousel-item {
    grid-row: 2/3;
  }
  .offer__carousel-title {
    font-size: 2.2rem;
  }
  .offer__subheading {
    font-size: 3.2rem;
  }
  .gallery {
    grid-template-columns: repeat(3, 200px);
    gap: 0.2em;
  }
  .gallery__heading, .gallery__decoration {
    grid-column: 1/4;
  }
  .gallery__item--active img {
    width: 600px;
  }
  .whyus__text {
    font-size: 2rem;
  }
  .whyus__container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    -moz-column-gap: 2em;
         column-gap: 2em;
    row-gap: 0;
    margin-top: 2em;
  }
  .whyus__item {
    margin-top: 2em;
  }
  .whyus__brand {
    flex-direction: row;
  }
  .contact {
    padding: 3em 2em;
  }
  .contact__heading {
    font-size: 3.2rem;
  }
  .contact__form {
    grid-template-columns: repeat(2, 1fr);
  }
  .contact__form-textarea {
    grid-column: 1/3;
  }
  .contact__form-button {
    grid-column: 1/3;
    justify-self: end;
  }
  .contact__modal {
    padding: 1em 2em;
  }
  .footer__container {
    grid-template-columns: 1.5fr repeat(2, 1fr) 1.5fr;
    grid-template-rows: repeat(4, 90px);
  }
  .footer__heading {
    grid-column: 1/2;
    font-size: 1.8rem;
  }
  .footer__list {
    grid-column: 4/5;
    grid-row: 1/3;
    align-self: start;
  }
  .footer__list-item:first-child {
    margin-top: 0;
  }
  .footer__logo {
    grid-column: 2/span 2;
    grid-row: 1/3;
    justify-self: center;
  }
  .footer__contact {
    grid-row: 3/4;
    grid-column: 1/2;
    align-self: end;
  }
  .footer__socialmedia {
    grid-column: 4/5;
    grid-row: 3/4;
    justify-self: end;
    align-self: end;
    gap: 2em;
  }
  .footer__author {
    grid-column: 1/5;
  }
}
@media screen and (min-width: 1024px) {
  .navbar {
    left: 50%;
    transform: translateX(-50%);
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 50px);
  }
  .navbar__burger-button {
    display: none;
  }
  .navbar__list {
    visibility: visible;
    display: flex;
    gap: 5rem;
    align-items: center;
    margin-top: 120px;
    transform: translate(0);
    background-color: transparent;
    padding-left: 0;
  }
  .navbar__list-item-image {
    display: inline;
    scale: 0.7;
  }
  .navbar__logo {
    display: none;
  }
  .navbar__language-box {
    grid-row: 1/2;
    grid-column: 1/2;
    position: relative;
    bottom: unset;
    visibility: visible;
    opacity: 1;
  }
  .navbar__language-button:first-child {
    padding-left: 0;
  }
  .navbar__socialmedia-box {
    grid-column: 2/3;
    grid-row: 1/2;
  }
  .navbar__socialmedia-link {
    padding-right: 0;
  }
  .header {
    background-image: var(--header-bgimg-desktop);
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .header__text {
    max-width: 600px;
  }
  .offer {
    margin-top: 10em;
  }
  .offer__image {
    grid-row: 2/4;
  }
  .offer__subheading {
    font-size: 3.8rem;
  }
  .offer__text--highlighted {
    padding: 0.5em;
  }
  .gallery {
    grid-template-columns: repeat(3, 300px);
    gap: 0.3em;
  }
  .gallery__item {
    height: 300px;
  }
  .whyus__bottomtext {
    margin-top: 4em;
  }
  .whyus__brand {
    margin-top: 5em;
  }
  .footer__heading {
    font-size: 2.4rem;
  }
  .footer__logo-image {
    width: 250px;
  }
  .footer__contact {
    font-size: 2rem;
  }
}
@media (min-width: 1200px) {
  .navbar__list-item-image {
    width: 150px;
  }
  .header__heading {
    font-size: 7.2rem;
    line-height: 52px;
  }
  .header__text {
    font-size: 3.2rem;
  }
  .header__container {
    margin-top: 8em;
    gap: 1.5em;
  }
  .offer {
    padding: 4em 1em 5em;
    margin-top: 14em;
  }
  .offer__image {
    grid-row: 3/4;
    justify-self: center;
  }
  .offer__heading {
    font-size: 5rem;
  }
  .offer__decoration {
    transform: scale(1.5);
  }
  .offer__background-decoration {
    top: 25%;
    transform: translate(-55%, -50%);
  }
  .offer__button {
    margin-top: 1.5em;
    font-size: 1.8rem;
  }
  .offer__subheading {
    margin-top: 1em;
  }
  .offer__carousel--main .offer__carousel-item {
    width: 33%;
    height: 450px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .gallery {
    grid-template-columns: repeat(3, 1fr);
  }
  .gallery__item {
    height: 400px;
  }
  .gallery__fullview {
    place-items: start center;
    padding-top: 3em;
  }
  .gallery__fullview-image {
    width: 700px;
    height: 520px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .gallery__heading {
    margin-bottom: 1em;
  }
  .contact {
    padding: 3em;
  }
  .contact__heading {
    font-size: 4.6rem;
  }
  .footer__contact {
    grid-row: 2/3;
    align-self: center;
  }
  .footer__socialmedia {
    grid-row: 2/4;
    align-self: center;
  }
}
@media (min-width: 1400px) {
  .offer__image {
    margin-top: 3em;
  }
}
@media (min-width: 1500px) {
  .header__container {
    width: 100%;
  }
  .whyus__container {
    justify-content: space-between;
    -moz-column-gap: 3em;
         column-gap: 3em;
  }
  .contact {
    margin-inline: auto;
  }
}